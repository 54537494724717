import { v4 as uuidv4 } from 'uuid'
const sm2 = require('sm-crypto').sm2
const sm4 = require('sm-crypto').sm4

// const keypair = sm2.generateKeyPairHex()
// const publicKey = keypair.publicKey // 公钥
// 04c58d49522f0152dcbf4ed220df094cf84646804ccc92b50c44e6802cac4cc2aad56dc7b99e0f3fccaafe45fe071f4961b94f245b29250af2f5342f4a959956ee
// 04c9beebaefdc4395640a05707123d1461ed4ea744f930720dcece1189c77827faf3d19158b6dfe1e04c68220a89d4fc6ffc81bb7de8d5088b62e63dc0762597f2
const publicKey = '04c58d49522f0152dcbf4ed220df094cf84646804ccc92b50c44e6802cac4cc2aad56dc7b99e0f3fccaafe45fe071f4961b94f245b29250af2f5342f4a959956ee'
// const privateKey = keypair.privateKey // 私钥
// abdf32ef0d9c9385eb0e143b989a419027fe98d3aa26dc4cf2067e7427003667
// 07ea8fd7fd6ed35621f13f03997c8eef421a09a87280cd6cd2a90b8e8f4f25af
const privateKey = 'abdf32ef0d9c9385eb0e143b989a419027fe98d3aa26dc4cf2067e7427003667'
// console.log('公钥:' + publicKey)
// console.log('私钥:' + privateKey)

export default {
  // 生成32位长的随机字符串
  generateKey () {
    let strUuid = uuidv4()
    strUuid = strUuid.replace(/-/g, '')
    return strUuid
  },
  // 非对称加密
  sm2Encrypt (text) {
    const cipherMode = 1 // 1 - C1C3C2，0 - C1C2C3，默认为1
    return sm2.doEncrypt(text, publicKey, cipherMode)
  },

  sm2Decrypt (encryptData) {
    const cipherMode = 1 // 1 - C1C3C2，0 - C1C2C3，默认为1
    return sm2.doDecrypt(encryptData, privateKey, cipherMode)
  },

  // 对称加密
  sm4Encrypt (body, key) {
    const encryptData = sm4.encrypt(body, key) // 加密，默认输出 16 进制字符串，默认使用 pkcs#5 填充
    // const encryptData = sm4.encrypt(body, key, { padding: 'none' }) // 加密，不使用 padding
    // const encryptData = sm4.encrypt(body, key, {padding: 'none', output: 'array'}) // 加密，不使用 padding，输出为字节数组
    // const encryptData = sm4.encrypt(body, key, { mode: 'cbc', iv: 'fedcba98765432100123456789abcdef' }) // 加密，cbc 模式
    return encryptData
  },

  sm4Decrypt (body, key) {
    const decryptData = sm4.decrypt(body, key) // 解密，默认输出 utf8 字符串，默认使用 pkcs#5 填充
    // const decryptData = sm4.decrypt(body, key, {padding: 'none'}) // 解密，不使用 padding
    // const decryptData = sm4.decrypt(body, key, {padding: 'none', output: 'array'}) // 解密，不使用 padding，输出为字节数组
    // const decryptData = sm4.decrypt(body, key, {mode: 'cbc', iv: 'fedcba98765432100123456789abcdef'}) // 解密，cbc 模式
    return decryptData
  }
}
