<template>
  <div class="title-bar" v-if="show">
    <van-nav-bar
      :title="barTitle"
      :left-arrow="leftArrow"
      :border="false"
      fixed
      z-index="999"
      :class="clazz"
      @click-left="$_clickLeftBtn"
    />
  </div>
</template>
<script>
import { NavBar } from 'vant'

export default {
  name: 'TitleBar',
  components: {
    [NavBar.name]: NavBar
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    leftArrow: {
      type: Boolean,
      default: true
    },
    clazz: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      barTitle: '',
      show: true
    }
  },
  watch: {
    title: {
      handler (val) {
        if (val) {
          this.$nextTick(() => {
            this.barTitle = val
          })
        }
      },
      immediate: true
    },
    $route: {
      handler (val) {
        this.barTitle = val.name || ''
      },
      immediate: true
    }
  },
  mounted () {
    // this.show = false
  },
  methods: {
    $_clickLeftBtn () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
// 默认标题栏样式
.title-bar {
  /deep/ .van-nav-bar {
    background: #fff;
  }
  /deep/ .van-nav-bar__title {
    color: #323233;
  }
  /deep/ .van-icon {
    color: #323233;
  }
}

// 测试用的标题栏样式
.title-bar2 {
  /deep/ .van-nav-bar {
    background: red;
  }
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
  /deep/ .van-icon {
    color: #fff;
  }
}
</style>
