<template>
  <div class="scroll-banner" :style="{ height: height }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'ScrollBanner',
  props: {
    height: {
      type: String,
      default: '200px'
    }
  },
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
.scroll-banner {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  height: 200px;
}
.scroll-banner::-webkit-scrollbar {
  display: none;
}
.scroll-item {
  width: 500px;
  height: 200px;
  display: inline-block;
}
</style>
