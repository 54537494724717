<template>
  <div class="hello">
    <h1 @click="$_clickMsg" v-click-outside="onClickOutside">{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    $_clickMsg () {
      console.log('点击HelloWorld组件')
    },
    onClickOutside () {
      console.log('点击HelloWorld组件--外部')
    }
  }
}
</script>

<style scoped lang="less">
.hello {
  margin: 40px 0;
  font-size: 24px;
}
</style>
