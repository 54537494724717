<template>
  <div ref="bannerSlide" class="banner-slide">
    <div ref="bannerSlideWrapper" class="banner-slide-wrapper" :style="{ height: height }">
      <slot></slot>
    </div>
    <div class="banner-slide-dots">
      <span
        class="dot"
        v-for="(item, index) in datas"
        :key="index"
        :class="{'active': (currentPageIndex === index) }"></span>
    </div>
  </div>
</template>

<script>
import BScroll from '@better-scroll/core'
import Slide from '@better-scroll/slide'

BScroll.use(Slide)

export default {
  name: 'SlideBanner',
  props: {
    interval: {
      type: Number,
      default: 5000
    },
    height: {
      type: String,
      default: '300px'
    },
    datas: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      slide: null,
      currentPageIndex: 0
    }
  },
  watch: {
    datas: {
      handler (val, oldVal) {
        setTimeout(() => {
          this.init()
        }, 20)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    init () {
      this.slide = new BScroll(this.$refs.bannerSlide, {
        scrollX: true,
        scrollY: false,
        slide: {
          loop: true,
          threshold: 100
        },
        useTransition: true,
        momentum: false,
        bounce: false,
        stopPropagation: false,
        interval: this.interval,
        probeType: 2
      })
      this.children = this.$refs.bannerSlideWrapper.children
      for (let i = 0; i < this.children.length; i++) {
        this.children[i].classList.add('slide-item')
      }
      this.autoGoNext()
      this.slide.on('scrollEnd', () => {
        this.autoGoNext()
      })
      this.slide.on('beforeScrollStart', () => {
        clearTimeout(this.playTimer)
      })
      this.slide.on('touchEnd', () => {
        this.autoGoNext()
      })
      this.slide.on('slideWillChange', (page) => {
        this.currentPageIndex = page.pageX
      })
    },
    nextPage () {
      this.slide.next()
    },
    autoGoNext () {
      clearTimeout(this.playTimer)
      this.playTimer = setTimeout(() => {
        this.nextPage()
      }, this.interval)
    }
  }
}

</script>

<style lang="less" scoped>
.banner-slide {
  width: 100%;
  overflow: hidden;
  position: relative;

  .banner-slide-wrapper {
    width: 100%;
  }

  .slide-item {
    float: left;
  }

  .banner-slide-dots {
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);

    .dot {
      display: inline-block;
      margin: 0 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #eee;
    }
    .active {
      width: 20px;
      border-radius: 5px;
    }
  }
}
</style>
