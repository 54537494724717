import focus from './focus'
import vClickOutside from 'v-click-outside'

const directives = {
  focus,
  clickOutside: vClickOutside.directive
}

export default {
  install (Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key])
    })
  }
}
