<template>
  <div id="home" class="home">
    <TitleBar
      title="首页"
      :left-arrow="false"
    />
    <div class="home-slide-banner">
      <SlideBanner height="4rem" :datas="banners">
        <div
          class="banner-item"
          :style="{ background: item.color }"
          v-for="item in banners"
          :key="item.id"
          @click="$_clickSlideBannerItem(item)"
        ></div>
      </SlideBanner>
    </div>
    <div class="home-scroll-banner">
      <ScrollBanner height="2.66667rem">
        <div
        class="scroll-item"
        :style="{ background: item.color }"
        v-for="item in banners"
        :key="item.id"
        @click="$_clickScollBannerItem(item)"
      ></div>
      </ScrollBanner>
    </div>
    <HelloWorld msg="Welcome to Your Vue.js App" />
    <input type="text" v-focus="false">
    <md-input-item
        type="money"
        v-model="price"
        placeholder="最多xxx万元"
        maxlength="11"
        is-amount
        is-highlight
      >
      </md-input-item>
    <md-button type="primary" round @click="$_click">测试弹框</md-button>
    <van-button type="primary" loading>默认按钮</van-button>

    <div class="home-scroll-banner">
      <ScrollBanner height="2.66667rem">
        <div
        class="scroll-item"
        :style="{ background: item.color }"
        v-for="item in banners"
        :key="item.id"
        @click="$_clickScollBannerItem(item)"
      ></div>
      </ScrollBanner>
    </div>

    <div class="home-scroll-banner">
      <ScrollBanner height="2.66667rem">
        <div
        class="scroll-item"
        :style="{ background: item.color }"
        v-for="item in banners"
        :key="item.id"
        @click="$_clickScollBannerItem(item)"
      ></div>
      </ScrollBanner>
    </div>

    <div class="home-scroll-banner">
      <ScrollBanner height="2.66667rem">
        <div
        class="scroll-item"
        :style="{ background: item.color }"
        v-for="item in banners"
        :key="item.id"
        @click="$_clickScollBannerItem(item)"
      ></div>
      </ScrollBanner>
    </div>

    <div class="home-scroll-banner">
      <ScrollBanner height="2.66667rem">
        <div
        class="scroll-item"
        :style="{ background: item.color }"
        v-for="item in banners"
        :key="item.id"
        @click="$_clickScollBannerItem(item)"
      ></div>
      </ScrollBanner>
    </div>

    <div class="home-scroll-banner">
      <ScrollBanner height="2.66667rem">
        <div
        class="scroll-item"
        :style="{ background: item.color }"
        v-for="item in banners"
        :key="item.id"
        @click="$_clickScollBannerItem(item)"
      ></div>
      </ScrollBanner>
    </div>

    <van-overlay z-index="1000" :show="show" :lock-scroll="false">
      <div class="dialog-content-wrapper">
        <div class="dialog-content" @click.stop v-scroll-lock="show">
          <div class="header">
            <div>温馨提示</div>
            <van-icon name="close" class="close" @click="$_clickClose" />
          </div>
          <div class="body">
            <div>111</div>
            <div>222</div>
            <div>333</div>
            <div>444</div>
            <div>555</div>
            <div>666</div>
            <div>777</div>
            <div>888</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import SlideBanner from '@/components/SlideBanner'
import ScrollBanner from '@/components/ScrollBanner'
import { Button as MdButton, InputItem as MdInputItem } from 'mand-mobile'
import { Button, Overlay, Icon, NavBar } from 'vant'
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    SlideBanner,
    ScrollBanner,
    [MdButton.name]: MdButton,
    [MdInputItem.name]: MdInputItem,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [Icon.name]: Icon,
    [NavBar.name]: NavBar
  },
  data () {
    return {
      banners: [
        { id: '1', color: '#f05b72' },
        { id: '2', color: '#2a5caa' },
        { id: '3', color: '#1d953f' }
      ],
      show: false,
      price: 1234.56
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.path === '/mine') {
        document.documentElement.scrollTop = to.meta.scollTopPosition
        document.body.scrollTop = to.meta.scollTopPosition
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    if (from.meta.keepAlive) {
      from.meta.scollTopPosition = scrollTop
    }
    next()
  },
  methods: {
    $_clickSlideBannerItem (item) {
      console.log('点击slideBanner:', item)
    },
    $_clickScollBannerItem (item) {
      console.log('点击scrollBannerItem:', item)
    },
    $_click () {
      this.show = true
    },
    $_clickClose () {
      this.show = false
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  .home-slide-banner {
    margin-bottom: 40px;
    .banner-item {
      display: inline-block;
      width: 100%;
      height: 100%;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
    }
  }
  .home-scroll-banner {
    margin-bottom: 40px;
    .scroll-item {
      width: 500px;
      height: 200px;
      display: inline-block;
    }
  }
}

.dialog-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .dialog-content {
    position: relative;
    z-index: 1002;
    margin: 0 40px;
    margin-top: -50px;
    width: 100%;
    height: 450px;
    border-radius: 6px;
    background-color: #fff;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .header {
      position: relative;
      height: 80px;
      line-height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 34px;
      color: #333;
      .close {
        position: absolute;
        top: 22px;
        right: 20px;
      }
    }
    .body {
      overflow-y: auto;
      max-height: 370px;
    }
  }
}

</style>
