import Vue from 'vue'
import FastClick from 'fastclick'
import 'mand-mobile/components/_style/global.styl'
import router from '@/router'
import store from '@/store'
import '@/assets/less/index.less'
import Directives from '@/directives/index.js'
import VScrollLock from 'v-scroll-lock'
import App from '@/App.vue'
import SmUtil from '@/utils/SmUtil.js'
import TitleBar from '@/components/TitleBar.vue'

Vue.component(TitleBar.name, TitleBar)

console.log('------')
requestEnc()
reponseDec()
console.log('------')

function requestEnc () {
  console.log('前端的发送请求开始加密...')
  const key = SmUtil.generateKey()
  const bodyObj = { token: '123abcd', text: '中国中华人民共和国', time: Date.now() }
  const body = JSON.stringify(bodyObj)

  console.log('原始请求报文key:' + key)
  console.log('原始请求报文body:' + body)

  const sm4EncryptBody = SmUtil.sm4Encrypt(body, key)
  const sm2EncryptKey = SmUtil.sm2Encrypt(key)

  console.log('请求key密文: ' + sm2EncryptKey)
  console.log('请求body密文:' + sm4EncryptBody)

  const sm2DecryptKey = SmUtil.sm2Decrypt(sm2EncryptKey)
  console.log('解密后请求key明文:' + sm2DecryptKey)
  if (sm2DecryptKey === key) {
    console.log('!!!sm2解密成功!!!')
  }
  const sm4DecryptBody = SmUtil.sm4Decrypt(sm4EncryptBody, sm2DecryptKey)
  console.log('解密后请求body明文:' + sm4DecryptBody)
  if (sm4DecryptBody === body) {
    console.log('!!!sm4解密成功!!!')
  }
}

function reponseDec () {
  const key = 'c52ed2fc98b4e25f1d2210a23e4c9c4f04b1603a8cba2b7d34b538adaff2c408094bbadf2c573ec1748e48bcbee7445e09df80d5176b58753693321e2fb922c44364fc711d3aa1e5d69391be57fa980bd8c9e461bb600e068c7958cb857a37d7fe01a3dc04cf7ff75141be2a4197d660ab64698f8966da2c6873cf459d863940'
  const body = 'EF9A5095CFBDAB5E6A8D03FA91868BB15D2EFB31AD8A01487E2A4AFE6F3DE1DF69C242C8467760F0E8EC5C3C396678BC658C17EC4C4D6C507CDEF34D0E98248710043E455CDACD83F77E44F03B787733'

  console.log('前端的接收响应开始解密...')
  console.log('SM2解密开始>>>>>>')
  console.log('加密key密文:' + key)
  const decKey = SmUtil.sm2Decrypt(key)
  console.log('解密key明文:' + decKey)
  console.log('<<<<<<SM2解密结束')

  console.log('SM4解密开始>>>>>>')
  console.log('sm4解密key:' + decKey)
  console.log('SM4-ECB-PKCS5Padding密文:' + body)
  const decBody = SmUtil.sm4Decrypt(body, decKey)
  console.log('SM4-ECB-PKCS5Padding解密结果:' + decBody)
  console.log('<<<<<<SM4解密结束')
}

Vue.use(Directives)
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true
  }
})

if ('addEventListener' in document && 'ontouchstart' in window) {
  // FastClick的ios点击穿透解决方案
  FastClick.prototype.focus = function (targetElement) {
    let length
    if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
      length = targetElement.value.length
      targetElement.focus()
      targetElement.setSelectionRange(length, length)
    } else {
      targetElement.focus()
    }
  }
  document.addEventListener('DOMContentLoaded', function () {
    FastClick.attach(document.body)
  }, false)
}

router.beforeEach((to, from, next) => {
  if (to.meta.keepAlive) {
    console.log('各个组件自身处理滚动位置')
  } else {
    window.scrollTo(0, 0) // 切换路由后回到顶部
  }
  next()
})

Vue.config.productionTip = false

console.log({ env: process.env.VUE_APP_ENV, version: process.env.VUE_APP_VERSION, baseUrl: process.env.VUE_APP_BASE_URL })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
